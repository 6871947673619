import React, { useEffect, useState } from "react";
import DataGrid, { Column, FilterRow, Sorting } from "devextreme-react/data-grid";
import { t } from "i18next";
import ChecklistsApi from "../../api/checklistsApi";
import { Checklist } from "../../api/types/checklist";
import { PagedList } from "../../api/types/pagedList"; // assuming you have this interface defined
import { Button, LoadIndicator } from "devextreme-react";
import "../checklists/checklists.scss"
import { useNavigate } from "react-router-dom";

export default function Checklists() {

  const [loading, setLoading] = useState<boolean>(false);
  const [checklists, setChecklists] = useState<Checklist[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);

  const pageSize = 100;
  const navigate = useNavigate();

  const fetchData = async (page: number) => {
    try {
      setLoading(true);
      const data: PagedList<Checklist> = await ChecklistsApi.getChecklists(false, page, pageSize);

      setChecklists((prevChecklists) => {
        const existingIds = new Set(prevChecklists.map((item) => item.idChecklist));
        const newItems = data.items.filter((item) => !existingIds.has(item.idChecklist));
        return [...prevChecklists, ...newItems];
      });

      setTotalCount(data.totalCount);
      setCurrentPage(page);

    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const addChecklist = () => {
    navigate(`/checklists/new`);
  };

  const handleEdit = (id: string) => {
    navigate(`/checklists/edit/${id}`);
  };

  useEffect(() => {
    fetchData(1);
  }, []);

  const hasMorePages = () => (currentPage * pageSize) < totalCount;

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{t("Checklists")}</h2>
      <Button
        className="add-button"
        text="Add"
        onClick={addChecklist}
        stylingMode="contained"
        type="default"/>
      <DataGrid
        className="data-grid-padding"
        keyExpr={"idChecklist"}
        dataSource={checklists}
        showBorders={false}
        focusedRowEnabled={true}
        columnAutoWidth={true}
        columnHidingEnabled={true}
      >
        <Sorting mode="none" />
        <FilterRow visible={false} />
        <Column dataField={"idChecklist"} caption={t("ID")} width={70} />
        <Column dataField={"code"} caption={t("Code")} />
        <Column dataField={"name"} caption={t("Name")} />
        <Column dataField={"description"} caption={t("Description")} />
        <Column dataField={"revisionNumber"} caption={t("Revision Number")} />
        <Column
          dataField={"revisionDate"}
          caption={t("Revision Date")}
          dataType={"date"}
          format={"HH:mm dd.MM.yyyy"}
        />
        <Column
          cellRender={(cellData) => {
              return (
                <Button
                  icon="edit"
                  hint="Edit"
                  onClick={() => handleEdit(cellData.data.idChecklist)}
                />
              );
          }}
        />
      </DataGrid>
      {hasMorePages() && (
        <div className="button-container">
          <Button onClick={() => fetchData(currentPage + 1)} stylingMode="contained" type="default">
            <LoadIndicator className="dx-button-indicator" visible={loading} />
            <span className="dx-button-text">{t("Load More")}</span>
          </Button>
        </div>
      )}
    </React.Fragment>
  );
}
