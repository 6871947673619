import { useTranslation } from "react-i18next";

export function useNavigationItems() {
  const { t } = useTranslation();

  const items = [
    {
      text: t("Home"),
      path: "/home",
      icon: "home",
    },
    {
      text: t("Profile"),
      path: "/profile",
      icon: "card",
    },
    {
      text: t("Checklists"),
      path: "/checklists",
      icon: "file",
    },
    {
      text: t("Schedule Checklists"),
      path: "/schedulechecklists",
      icon: "clock",
    },
    {
      text: t("Reports"),
      path: "/reports",
      icon: "dataarea",
    },
    {
      text: t("About Care4iT"),
      path: "/about",
      icon: "info",
    },
  ];

  return items;
}
