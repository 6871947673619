import React, { useState, useEffect } from "react";
import "../addchecklists/addchecklists.scss";
import notify from 'devextreme/ui/notify';
import { Button, LoadIndicator, TextArea, TextBox, Validator } from "devextreme-react";
import { RequiredRule } from "devextreme-react/cjs/data-grid";
import ChecklistsApi from "../../../api/checklistsApi";
import { useNavigate, useParams } from "react-router-dom";

export default function AddEditChecklist() {
  const params = useParams<{ id: string }>();
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);

   const navigate = useNavigate();

  useEffect(() => {
    if (params.id) {
      setIsLoading(true);
      ChecklistsApi.getChecklistById(params.id).then(response => {
        if (response!= null) {
          const { name, code, description } = response;
          setName(name);
          setCode(code);
          setDescription(description);
        } else {
          notify("Failed to load checklist data.", "error", 2500);
        }
        setIsLoading(false);
      }).catch(() => {
        notify("Failed to load checklist data.", "error", 2500);
        setIsLoading(false);
      });
    }
  }, [params.id]);

  function showNotification(message: string, type: string, duration: number) {
    notify(
        {
            message,
            type,
            displayTime: duration,
            width: 300,
        },
        {
            position: 'top right',
            direction: 'down-push'
        }
    );
}
const handleSaveChecklist = async () => {
  setIsLoading(true);

  if (!name) {
      notify("Please fill in name.");
      setIsLoading(false);
      return;
  }

  const checklist = { name, code, description };

  try {
      const result = params.id
          ? await ChecklistsApi.updateChecklist(params.id, checklist)
          : await ChecklistsApi.addChecklist(checklist);

      if (result.succeeded) {
          showNotification(`Checklist ${params.id ? "updated" : "added"} successfully`, 'success', 2500);
          if (!params.id && result.id) {
            navigate(`/checklists/edit/${result.id}`);
        }
      } else {
          showNotification(`Failed to ${params.id ? "update" : "add"} checklist. ${result.errorMessage}`, 'error', 2500);
      }
  } catch (error) {
      showNotification(`An unexpected error occurred while ${params.id ? "updating" : "adding"} the checklist.`, 'error', 2500);
  } finally {
      setIsLoading(false);
  }
};

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{params.id ? "Edit Checklist" : "Add Checklist"}</h2>

      <div className="form-group">
        <LoadIndicator className="loading-overlay" visible={isLoading} />
        <div className={"dx-card responsive-paddings"}>
            <>

              <TextBox
                disabled={isLoading}
                className="add-checklist-input"
                placeholder="Enter name here..."
                label="Name *"
                value={name}
                onValueChanged={(e) => setName(e.value)}
              >
                <Validator>
                  <RequiredRule message="Name is required" />
                </Validator>
              </TextBox>
              <TextBox
                disabled={isLoading}
                className="add-checklist-input"
                placeholder="Enter code here..."
                label="Code"
                value={code}
                onValueChanged={(e) => setCode(e.value)}
              >
              </TextBox>
              <TextArea
                disabled={isLoading}
                className="add-checklist-input"
                placeholder="Enter description here..."
                label="Description"
                value={description}
                onValueChanged={(e) => setDescription(e.value)}
              />
              <Button
                disabled={isLoading}
                className="add-checklist-button"
                text="Save"
                stylingMode="contained"
                type="default"
                onClick={handleSaveChecklist}
              />
              <Button
                disabled={isLoading}
                className="add-checklist-button"
                text="Discard"
                stylingMode="outlined"
                type="default"
                onClick={() => {
                  window.history.back();
                }}
              />
            </>

        </div>
      </div>
    </React.Fragment>
  );
}
