import {BACKENDURL} from "./constants";

export default class HttpClient {

    static async get(url: string):Promise<Response> {
        return await fetch(
            `${BACKENDURL}${url}`,
            {
              method: "GET",
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
    }

    static async post(url: string, options: { headers: HeadersInit, body: string }): Promise<Response> {
      return await fetch(
          `${BACKENDURL}${url}`,
          {
            method: "POST",
            credentials: "include",
            headers: {
              ...options.headers,
              "Content-Type": "application/json",
            },
            body: options.body,
          }
        );
    }

    static async put(url: string, options: { headers: HeadersInit; body: string }): Promise<Response> {
      return await fetch(
        `${BACKENDURL}${url}`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            ...options.headers,
            "Content-Type": "application/json",
          },
          body: options.body,
        }
      );
    }
  }